import { useRouter } from 'next/router';
import { useEffectOnce } from 'react-use';
import Statsig from 'statsig-js-local-eval';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';

export const useLogEvent = (name: string, options = {}) => {
  const router = useRouter();

  const { countryCode } = getCountryConfigFromLocale(router.locale!);

  useEffectOnce(() => {
    Statsig.logEvent({ userID: Statsig.getStableID() }, name, '', {
      country: countryCode,
      ...options,
    });
  });
};
