import { checkForDefaultLocale } from '@/lib/i18n/checkForDefaultLocale';
import { getLangFromLocale } from '@/lib/i18n/getLangFromLocale';
import { gtmDataLayerPush } from './gtmDataLayerPush';

function getUserId() {
  // TODO userid
  // return sha256 + salt hashed customer ID (e.g. CRM customer id or email address), as known by back office systems
  return undefined;
}

export function gtmInitialDataLayer(locale: string) {
  const userId = getUserId();

  const pageLanguage = checkForDefaultLocale(locale)
    ? locale
    : getLangFromLocale(locale);

  const dataLayer = {
    userId,
    pageType: undefined,
    pageLanguage,
  };
  gtmDataLayerPush(dataLayer);
}
