export interface TagManagerArgs {
  gtmId: string;
  events?: Record<string, unknown>;
  auth?: string;
  preview?: string;
}

export function gtmSnippets({
  gtmId,
  events = {},
  preview = '',
  auth = '',
}: TagManagerArgs) {
  const gtm_auth = `&gtm_auth=${auth}`;
  const gtm_preview = `&gtm_preview=${preview}`;

  if (!gtmId) {
    console.warn('[Google Tag Manager Snippet]', 'GTM Id is required');
  }

  const iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}${gtm_auth}${gtm_preview}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager" data-cookieconsent="ignore"/>`;

  const initiateGtmScriptEvent = `(function(w,l){w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
      events
    ).slice(1, -1)}});
  })(window,'dataLayer');`;

  const gtmScriptSrc = `https://www.googletagmanager.com/gtm.js?id=${gtmId}${gtm_auth}${gtm_preview}&gtm_cookies_win=x`;

  return {
    iframe,
    gtmScriptSrc,
    initiateGtmScriptEvent,
  };
}
