import { NextRouter } from 'next/router';
import { ProductKeys } from '@/data/productsConfig';

export type PageNamesProps = typeof PageNames[keyof typeof PageNames];

export const PageNames = {
  countrySelector: 'Country selector',
  home: 'Home',
  productOverview: 'Product overview',
  productOverviewEbikes: 'Product overview ebikes',
  productDetail: 'Product detail',
  productDetailEBikes: 'Product detail ebikes',
  productDetailPedalBikes: 'Product detail pedal bikes',
  productConfigure: 'Product configure',
  cookies: 'Cookies',
  notFound: '404',
} as const;

const routePageNameMap: { [key: string]: PageNamesProps } = {
  '/': PageNames.home,
  '/bikes': PageNames.productOverview,
  '/[city]': PageNames.productOverview,
  '/ebikes': PageNames.productOverviewEbikes,
  '/[city]/[product]/configure': PageNames.productConfigure,
  '/cookies': PageNames.cookies,
};

const productPageNameMap: { [key in ProductKeys]?: PageNamesProps } = {
  original: PageNames.productDetailPedalBikes,
  'original-plus': PageNames.productDetailPedalBikes,
  'deluxe-7': PageNames.productDetailPedalBikes,
  'power-1': PageNames.productDetailEBikes,
  'power-7': PageNames.productDetailEBikes,
};

export const mapRoutesToPageNames = ({ route, query }: NextRouter) => {
  if (route === '/bikes/[product]') {
    return (
      productPageNameMap[query.product as ProductKeys] ||
      PageNames.productDetail
    );
  }

  return routePageNameMap[route] || PageNames.notFound;
};

export type RoutesToPageNamesMapping = keyof typeof mapRoutesToPageNames;
