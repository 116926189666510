import { useRouter } from 'next/router';
import { useEffectOnce } from 'react-use';
import { initiateGtmPageGrouping } from '@/lib/gtm/gtmPageGrouping';

export const useGtmPageView = () => {
  const router = useRouter();

  useEffectOnce(() => {
    const handleGtmPageGrouping = (pathname: string) => {
      initiateGtmPageGrouping(pathname, router.locale === 'en');
    };
    router.events.on('routeChangeStart', handleGtmPageGrouping);
    return () => {
      router.events.off('routeChangeStart', handleGtmPageGrouping);
    };
  });
};
