import { languages, Languages } from '@/types/Languages';

export const getLangFromLocale = (currentLocale: string) => {
  const languageCode = currentLocale.split(/[-_]/)[0];

  const checkLang = languages[languageCode as Languages] ?? null;

  if (!checkLang) {
    throw new Error(
      `${languageCode} code is missing in configuration (@/data/languages)`
    );
  }

  return checkLang;
};
