import Statsig, { StatsigUser } from 'statsig-js-local-eval';
import { Countries } from '@/types/Countries';
import { isBrowser } from '@/utils/isBrowser';
import { EXPERIMENTS } from './constants';
import { ExperimentName } from './types';

const ENVIRONMENT_TIER =
  process.env.NEXT_PUBLIC_ENV === 'dev'
    ? 'development'
    : process.env.NEXT_PUBLIC_ENV;

export const initializeStatsig = () => {
  if (isBrowser) {
    // Override the stableID for the framer_homepage experiment
    // https://dev.azure.com/swapfiets/Digital/_workitems/edit/26299
    // https://www.statsig.com/faq/how-do-you-create-a-stable-id-for-server-side-experiments
    // Also see the `src/middleware.ts`
    const statsigUID = document.cookie
      .split('; ')
      .find(row => row.startsWith('statsig_uid='))
      ?.split('=')[1];

    if (!statsigUID) {
      console.error('statsig_uid is not found in cookies');
    }

    Statsig.initialize(process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY!, {
      initializeValues: window.statsigConfigSpecs,
      environment: {
        tier: ENVIRONMENT_TIER,
      },
      ...(statsigUID ? { overrideStableID: statsigUID } : {}),
    });
  }
};

export const getExperiment = (name = '', user?: StatsigUser) =>
  Statsig.getExperiment(
    {
      ...user,
      userID: Statsig.getStableID(),
    },
    name
  );

export const isExperimentActive = (
  name: ExperimentName,
  countryCode?: Countries
) => {
  const experiment = getExperiment(EXPERIMENTS[countryCode || 'ALL']?.[name]);
  return experiment.get('targetGroup', null) === 'B';
};

export const getExperimentTargetGroup = (
  name: ExperimentName,
  countryCode?: Countries
) => {
  const experiment = getExperiment(EXPERIMENTS[countryCode || 'ALL']?.[name]);
  return experiment.get('targetGroup', null);
};

export const getConfig = (name: string, user?: StatsigUser) =>
  Statsig.getConfig({ ...user, userID: Statsig.getStableID() }, name);

export const checkGate = (user: StatsigUser, name: string) =>
  Statsig.checkGate(
    {
      ...user,
      customIDs: {
        stableId: Statsig.getStableID(),
      },
    },
    name
  );
