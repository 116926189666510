import { isBrowser } from '@/utils/isBrowser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function gtmDataLayerPush(dataLayer: Record<string, unknown>) {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(dataLayer);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function gtmDataLayerPushWithMultipleArgs(..._: any) {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
}
