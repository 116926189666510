import localesConfig from '@/config/config.locales.json';
import { Countries } from '@/types/Countries';

export function getCountryConfigFromLocale(currentLocale: string) {
  const countryLocaleConfig = Object.entries(localesConfig).find(
    ([_country, config]) => {
      return config.locales.find(locale => locale.key === currentLocale);
    }
  );

  if (!countryLocaleConfig)
    throw new Error(`locale config not found ${currentLocale}`);

  return {
    countryCode: countryLocaleConfig[0] as Countries,
    localeConfig: countryLocaleConfig[1],
  };
}
