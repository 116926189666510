// To keep track of all visitors when they opt out of cookies,
// the preference and statistics setting must be set to true.
// More info about Cookiebot https://www.cookiebot.com/en/developer/
// TODO: delete it when the next ticket is done
// https://swapfiets.visualstudio.com/Digital/_workitems/edit/18568/
export const addCustomConsentOnDecline = ({
  preference = false,
  statistics = false,
  marketing = false,
}) => {
  window?.addEventListener('CookiebotOnDialogDisplay', e => {
    e.preventDefault();
    const declineBtn = document.getElementById(
      'CybotCookiebotDialogBodyButtonDecline'
    );
    declineBtn?.addEventListener(
      'click',
      e => {
        e.preventDefault();
        // this is a hack to override the default CookiebotOnDecline behavior
        // that sets all consent values ​​to false
        setTimeout(() => {
          window?.cookiebot?.submitCustomConsent?.(
            preference,
            statistics,
            marketing
          );
        }, 100);
      },
      { once: true }
    );
  });
};
