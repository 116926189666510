export const languages = {
  da: 'da',
  de: 'de',
  en: 'en',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  es: 'es',
} as const;

export type Languages = (typeof languages)[keyof typeof languages];
export type LanguagesData = Record<Languages, { label: string }>;
