import { gtmDataLayerPush } from '@/lib/gtm/gtmDataLayerPush';
import { PageNames, PageNamesProps } from '@/utils/mapRoutesToPageNames';

export const gtmPageViewDataLayer = (pageType: PageNamesProps) => {
  gtmDataLayerPush({
    event: 'pageInformation',
    pageType,
  });
};

export const initiateGtmPageGrouping = (
  route: string,
  isDefaultLocale: boolean
) => {
  const cleanRoute = route.split('?')[0];
  const isProductRelated = cleanRoute.match(/\/[a-z-0-9-\-]+$/g);

  if (cleanRoute.includes('/checkout')) return;

  if (cleanRoute.includes('/configure')) {
    gtmPageViewDataLayer(PageNames.productConfigure);
  } else if (cleanRoute.includes('/cookies')) {
    gtmPageViewDataLayer(PageNames.cookies);
  } else if (isProductRelated && cleanRoute.includes('/bikes/')) {
    gtmPageViewDataLayer(PageNames.productDetail);
  } else if (isProductRelated && !cleanRoute.includes('/configure')) {
    gtmPageViewDataLayer(PageNames.productOverview);
  } else {
    if (!isDefaultLocale) {
      gtmPageViewDataLayer(PageNames.home);
    } else {
      gtmPageViewDataLayer(PageNames.countrySelector);
    }
  }
};
